.StripeElement {
    font-family: Roboto, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    border-radius: 8px;
    padding: 10px 16px !important;
    border: 1px solid rgba(0, 0, 0, 0.23)
}
.StripeElement .__PrivateStripeElement {
    height: 21px !important;
}